export const uz = {
    add: 'Qoʻshish',
    address: 'Manzil',
    cancel: 'Bekor qilish',
    confirm: 'Ishonchingiz komilmi?',
    contract: 'Shartnoma',
    email: 'Elektron pochta',
    firstname: 'Ism',
    lang: "Uz",
    lastname: 'Familiya',
    login: 'Kirish',
    loginTitle: 'Tizimga kirish',
    name: 'Nomi',
    networkError: 'Serverga ulanishda xato',
    no: 'Yoʻq',
    noData: 'Maʼlumot yoʻq',
    noRoutes: 'Administrator sizga marshrut koʻrsatmagan',
    ok: 'Ha',
    password: 'Parol',
    phone: 'Telefon raqami',
    price: 'Narxi',
    product: 'Mahsulot',
    registration: 'Roʻyxatdan oʻtish',
    repeatThePassword: 'Parolni takrorlang',
    required: 'Iltimos, {{input}}ni kiriting!',
    role: 'Rola',
    save: 'Saqlash',
    store: 'Ombor',
    update: 'Oʻzgartirish',
    username: 'Foydalanuvchi nomi',
    ROLE_ADMIN: "ADMIN",
    ROLE_USER: "FOYDALANUVCHI",
    action: "Amallar",
    search: "{{name}}ni qidiring",
    year: "Yil",
    month: "Oy",
    day: "Kun",
    hour: "Soat",
    minute: "Daqiqa",
    second: "Soniya",
    sectionAccess: "Boʻlimga kirish",
    delete: 'Oʻchirish',
    select: 'Tanlash',
    from: 'dan',
    to: 'ga',
    selectPage: 'Sahifani tanlang',
    nds: 'QQS',
    noPermission: 'Ruxsat yoʻq',
    permission: 'Ruxsat',
    empty: '(boʻsh)',
    fullName: 'Toʻliq ismi',
    users: 'Foydalanuvchilar',
    region: 'Hudud',
    inn: 'STIR',
    code: "Kod",
    mfo: 'MFO',
    hide: 'Yashirish',
    history: 'Tarix',
    createdDate: 'Yaratilgan sana',
    size: 'Hajmi',
    download: 'Yuklab olish',
    delete_confirm: 'Oʻchirmoqchi ekanligingizga ishonchingiz komilmi?',
    status: 'Holati',
    bank: 'Bank',
    productName: 'Mahsulot nomi',
    invoiceNum: 'Hisob-faktura raqami',
    expirationDate: 'Muddati',
    document: "Hujjat",
    act: "Akt",
    acceptAccount: "Tasdiqlagan Akkaunt",
    returnAct: "Qaytarilgan Akt",
    returnActAccepted: "Принятые возврат акта",
}