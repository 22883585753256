export const eng = {
    add: 'Add',
    address: 'Address',
    cancel: 'Cancel',
    confirm: 'Are you sure?',
    contract: 'Contract',
    email: 'Email',
    firstname: 'First name',
    lang: "En",
    lastname: 'Last name',
    login: 'Login',
    loginTitle: 'Sign in',
    name: 'Name',
    networkError: 'Server connection error',
    no: 'No',
    noData: 'No data',
    noRoutes: 'The administrator has not assigned you a route',
    ok: 'Yes',
    password: 'Password',
    phone: 'Phone number',
    price: 'Price',
    product: 'Product',
    registration: 'Registration',
    repeatThePassword: 'Repeat the password',
    required: 'Please enter {{input}}!',
    role: 'Role',
    save: 'Save',
    store: 'Warehouse',
    update: 'Update',
    username: 'Username',
    ROLE_ADMIN: "ADMIN",
    ROLE_USER: "USER",
    action: "Actions",
    search: "Search {{name}}",
    year: "Year",
    month: "Month",
    day: "Day",
    hour: "Hour",
    minute: "Minute",
    second: "Second",
    sectionAccess: "Section access",
    delete: 'Delete',
    select: 'Select',
    from: 'from',
    to: 'to',
    selectPage: 'Select page',
    nds: 'VAT',
    noPermission: 'No permission',
    permission: 'Permission',
    empty: '(empty)',
    fullName: 'Full name',
    users: 'Users',
    region: 'Region',
    inn: 'INN',
    code: "Code",
    mfo: 'MFO',
    hide: 'Hide',
    history: 'History',
    createdDate: 'Created date',
    size: 'Size',
    download: 'Download',
    delete_confirm: 'Are you sure you want to delete?',
    status: 'Status',
    bank: 'Bank',
    productName: 'Product name',
    invoiceNum: 'Invoice number',
    expirationDate: 'Expiration date',
    document: "Document",
    act: "Act",
    acceptAccount: "Accept account",
    returnAct: "Return act",
    returnActAccepted: "Return act accepted",
}